import React from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import { YellowButton } from './button';
import { colors } from '../styles/colors';

export const TermsOfUse = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} p={6}>
                <ModalCloseButton color="white" />
                <Text fontSize="xl" color={colors.white75} mb={4}>
                    Termos de Uso
                </Text>
                <ModalBody maxHeight="70vh" overflowY="auto">
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Este Termos de Uso estabelece os termos e condições sob os quais você pode acessar e usar este site e/ou aplicativo e/ou outras plataformas futuras oferecidas pela Empresa HausTri que é fornecido e disponibilizado para você pelo proprietário do site: https: www.haustri.com
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Observe que ao acessar e/ou usar o Site você aceita estes Termos e a Política de Privacidade e concorda em ficar vinculado a eles. Portanto é sua obrigação ler atentamente estes Termos e a Política de Privacidade e certificar-se de que concorda com os mesmos antes de qualquer acesso ou utilização do site.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Informamos que ao adquirir nossos ativos você também estará sujeito à Licença (“Licença”) que deverá ser lida em conjunto com estes Termos e a Política de Privacidade.
                        Você declara e garante que: (1) leu o Contrato entendeu o que está declarado nele e concorda em se envolver com a Empresa sob seus termos; (2) sua idade não o limita de se envolver legalmente no Contrato e que você não está legalmente proibido de celebrar o Contrato; (3) você está autorizado a celebrar e executar o Contrato para si mesmo ou para a entidade cujos detalhes foram especificados no momento da assinatura de nossos serviços.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>1. Abrindo uma conta</strong><br />
                        Para usar os serviços da Empresa e receber uma licença para usar os Ativos conforme especificado nestes Termos e na Licença você deve (I) abrir uma conta no Site e (II) nos fornecer seus dados precisos e completos. Você está proibido de nos fornecer um endereço de e-mail inexistente ou que não lhe pertença se passar por outra pessoa ou entidade ou nos enganar de qualquer outra forma em relação à sua identidade ou informações sobre sua forma de pagamento.
                        Você é obrigado a proteger a confidencialidade e a segurança dos detalhes da conta (nome de usuário e senha) e será responsável total e exclusivamente por todas as atividades em sua conta. Você deve nos informar imediatamente sobre qualquer uso não autorizado de sua conta.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>2. Política de Privacidade</strong><br />
                        Nós realmente nos preocupamos com sua privacidade e fazemos nossos melhores esforços para sermos totalmente transparentes sobre o uso de suas informações. Por favor revise nossa Política de Privacidade para saber como coletamos e usamos suas informações pessoais e cookies.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>3. Direitos de propriedade intelectual</strong><br />
                        Todas as informações os Ativos e conteúdos incluídos no Site e os serviços oferecidos pela Empresa nome da Empresa marcas registradas e logotipos incluindo sem limitação aqueles usados ​​no Site são protegidos por direitos autorais e direitos de propriedade intelectual da Empresa ou de seus artistas (“Conteúdo Proprietário”).
                        Você está proibido de usar o nome marcas registradas e logotipos do Site e/ou da Empresa incluindo sem limitação aqueles usados ​​no Site. Além disso conforme especificado na Licença você está proibido de usar os nomes originais dos Ativos e/ou os nomes dos artistas ou nomes artísticos.
                        Você reconhece e confirma que não tem e não terá qualquer propriedade e/ou direitos de propriedade intelectual sobre o Conteúdo Proprietário e/ou vídeos e/ou os Ativos e/ou o conteúdo do Site e/ou as marcas registradas do Site e seus design e que seu direito está restrito aos direitos limitados concedidos a você nestes Termos e na Licença.
                        O Contrato concede a você uma autorização para usar o Site e os Ativos estritamente de acordo com as disposições do Contrato e está condicionado ao seu compromisso de não violar ou se envolver em quaisquer atividades que violem quaisquer termos do Contrato e especificamente em atividades que causar a violação dos direitos de propriedade intelectual e/ou desvio das disposições da Licença.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>4. Política de Notificação de Violação de Direitos Autorais</strong><br />
                        Levamos a proteção dos direitos autorais muito a sério. Se você acredita que algum Ativo infringe sua propriedade intelectual ou outros direitos consulte nossa Política de Notificação de Violação de Direitos Autorais.
                        Se formos notificados de que algum Ativo infringe direitos autorais de terceiros ou outros direitos poderemos a nosso exclusivo critério remover tais Ativos do Site ou tomar outras medidas que considerarmos necessárias sem qualquer notificação prévia.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>5. Direitos da empresa em caso de violação em seu nome</strong><br />
                        Você reconhece que qualquer uso não autorizado por você e/ou por qualquer pessoa em seu nome de qualquer um dos Ativos ou do Site ou de uma maneira que se desvie das condições do Contrato constitui uma violação do Contrato bem como uma violação do leis de direitos autorais e a Empresa e qualquer pessoa em seu nome têm todo o direito de exercer todos os direitos e recursos disponíveis por lei nesse caso. Na medida em que a violação é cometida por terceiros usando Ativos que foram baixados por você (por exemplo: seus funcionários contratados clientes) você é responsável por tal violação e é obrigado a tomar todas as medidas possíveis ações para cessar imediatamente a violação.
                        A Empresa terá o direito entre outras soluções a seu exclusivo critério e sem qualquer aviso prévio de bloquear seu acesso ao Site e/ou seus serviços temporária ou permanentemente caso você viole qualquer lei aplicável e/ou quaisquer termos de do Contrato ou em caso de interrupção da atividade regular da Empresa ou de seu Site e você não terá nenhuma reclamação ou demanda a esse respeito.
                        A Empresa reserva-se o direito de divulgar suas informações pessoais ou informações sobre seu uso do Site incluindo o conteúdo usado nele sem obter sua permissão se tal divulgação for necessária para: (1) obedecer a requisitos legais ou cumprir ordens emitidas em um processo legal ou por uma autoridade competente por qualquer lei; (2) fazer cumprir o Contrato; ou (3) proteger os direitos da Empresa ou de qualquer outra pessoa no caso de violação do Contrato por você ou qualquer pessoa em seu nome.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>6. Isenção de responsabilidade</strong><br />
                        VOCÊ ENTENDE E CONCORDA EXPRESSAMENTE QUE: (I) O SITE E OS SERVIÇOS FORNECIDOS PELA EMPRESA SÃO FORNECIDOS 'NO ESTADO EM QUE SE ENCONTRAM' E 'CONFORME DISPONÍVEIS' SEM GARANTIA DE QUALQUER TIPO INCLUINDO SEM LIMITAÇÃO ÀS GARANTIAS DE COMERCIALIZAÇÃO ADEQUAÇÃO A UM DETERMINADO OBJETIVO NÃO VIOLAÇÃO E QUALQUER OUTRA GARANTIA QUE POSSA SURGIR SOB QUALQUER LEI; (II) A EMPRESA NÃO GARANTE QUE O SITE OU SEUS SERVIÇOS SERÃO ININTERRUPTOS OU IMUNES A DANOS MAU FUNCIONAMENTO DEFEITOS OU FALHAS NO HARDWARE SOFTWARE SISTEMAS E LINHAS DE COMUNICAÇÃO NO SITE OU EM QUALQUER DE SEUS FORNECEDORES; (III) A EMPRESA NÃO SERÁ RESPONSÁVEL CASO ALGUM DOS ATIVOS NÃO ESTEJA DISPONÍVEL NO SITE POR QUALQUER MOTIVO DURANTE A VIGÊNCIA DO CONTRATO; (IV) A EMPRESA NÃO TERÁ QUALQUER RESPONSABILIDADE PELO USO DE QUAISQUER OUTROS CONTEÚDOS ADICIONAIS QUE VOCÊ POSSA INTEGRAR NOS PROJETOS E VOCÊ SERÁ O ÚNICO RESPONSÁVEL TOTAL E EXCLUSIVO POR UTILIZÁ-LOS; (V) A EMPRESA RESERVA-SE O DIREITO DE REMOVER E/OU ADICIONAR ATIVOS AO SITE A QUALQUER MOMENTO A SEU EXCLUSIVO CRITÉRIO E VOCÊ NÃO TERÁ RECLAMAÇÕES OU ARGUMENTOS A ESTE RESPEITO; (VI) A EMPRESA NÃO SERÁ RESPONSÁVEL POR QUAISQUER DANOS DIRETOS OU INDIRETOS PECUNIÁRIOS OU OUTROS QUE VOCÊ POSSA INCORRER EM CONSEQUÊNCIA: (1) ALTERAÇÕES QUE A EMPRESA POSSA FAZER NO SITE E SEUS SERVIÇOS TODOS OU QUALQUER DELES; (2) CESSAR  TEMPORARIAMENTE OU PERMANENTEMENTE A PRESTAÇÃO DE SEUS SERVIÇOS TODOS OU QUALQUER DELES; (3) ALTERAR ADICIONAR OU REMOVER QUALQUER CARÁTER OU PROPRIEDADE DOS SERVIÇOS TODOS OU QUALQUER DELES; (4) ALTERAR ADICIONAR OU REMOVER QUALQUER CONTEÚDO  GRAVAÇÕES OU ATIVOS DO SITE TODOS OU QUALQUER DELES. (VII) A EMPRESA OU QUALQUER UM DE SEUS DIRETORES FUNCIONÁRIOS ACIONISTAS OU AGENTES NÃO SERÁ RESPONSÁVEL POR QUALQUER TIPO DE DANO EM RELAÇÃO A QUALQUER RECLAMAÇÃO PERDA OU DANO DECORRENTE DESTE CONTRATO.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>7. Serviços de Terceiros</strong><br />
                        Ao usar os serviços da Empresa você também pode usar os serviços de um ou mais terceiros como provedores de serviços de internet ou de pagamento. Esses terceiros podem ter os seus próprios termos de utilização acordos e políticas que se podem aplicar a si. Você reconhece e concorda que a Empresa não é responsável por quaisquer serviços e termos de terceiros nem pela conformidade com eles. Recomendamos fortemente que você leia atentamente quaisquer termos de uso e políticas de terceiros e certifique-se de cumpri-los.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>8. Alterações a estes Termos</strong><br />
                        A Empresa tem o direito de alterar as condições destes Termos da Licença e da Política de Privacidade de tempos em tempos a seu exclusivo critério. Ao realizar as alterações a nova versão dos Termos Licença e/ou Política de Privacidade será publicada no Site. É aconselhável verificar atualizações regularmente. No caso de alterações materiais poderemos fornecer-lhe um aviso. Se você não concordar com as alterações em tais termos você deverá parar de acessar e usar o Site e os serviços da Empresa e parar de baixar os Ativos. Ao continuar a acessar ou usar o Site os serviços e os Ativos você concorda em ficar vinculado à versão atualizada do Contrato que se aplicará a você em sua totalidade.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>9. Avisos</strong><br />
                        Você concorda que poderemos enviar-lhe mensagens de e-mail referentes aos serviços da Empresa e outros assuntos diversos inclusive em relação aos benefícios concedidos aos usuários da Empresa. Caso não queira receber nossos e-mails promocionais ou de marketing você pode retirar seu consentimento a qualquer momento seguindo as instruções em nossa Política de Privacidade.
                        As notificações de acordo com o Contrato serão feitas por escrito e enviadas por e-mail de acordo com o endereço de e-mail que você especificou no formulário de registro. Qualquer entrega de um aviso por e-mail conforme indicado acima será considerada uma entrega legal.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>10. Geral</strong><br />
                        O Contrato constitui o acordo integral entre você e a Empresa com relação ao seu acesso e uso do Site dos Ativos e dos serviços da Empresa e qualquer acordo e/ou compromisso e/ou declaração anterior ou futuro não incluído especificamente no Contrato não será válido. Você renuncia antecipadamente a qualquer reclamação segundo a qual o Contrato foi alterado e/ou cancelado por conduta ou por qualquer correspondência entre você e a Empresa ou qualquer pessoa em seu nome.
                        Se na medida em que qualquer disposição do Contrato for decidida pelo tribunal como inválida ilegal ou inexequível isso não derrogará a validade das outras disposições do Contrato.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>11. Lei Aplicável e Jurisdição</strong><br />
                        Considera-se as leis constitucionais do Brasil para dar validade a este Acordo sem dar efeito a quaisquer regras ou princípios de conflito de leis e a jurisdição para ouvir qualquer questão resultante ou relativa ao Acordo direta ou indiretamente será dada exclusiva aos tribunais de Santa Cruz do Sul RS - Brasil e a nenhum outro tribunal em qualquer outro lugar.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Em vigor em maio de 2024.
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w="full">
                        <YellowButton text="Fechar" onClick={onClose} />
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
