import { Box, VStack, Button, Container, Flex, Grid, HStack, Image, Link, Stack, Text, Center } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaUpload } from 'react-icons/fa';
import { HowItWorks } from '../components/howItWorks';
import { PrivacyPolicy } from '../components/privacyPolicy';
import { ProblemReport } from '../components/problemReport';
import { TermsOfUse } from '../components/termsOfUse';
import { useAuth } from '../hooks/auth';
import Api from '../services/api';
import { colors } from '../styles/colors';
import { YellowButton } from './button';

export const Footer = ({ ...rest }) => {
    const { user, headerExpand, faturamentoOpenModal } = useAuth();

    const [howItWorks, setHowItWorks] = useState(false);
    const [problemReport, setProblemReport] = useState(false);
    const [termsOfUse, setTermsOfUse] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [accountDetails, setAccountDetails] = useState({});
    const [dataFetched, setDataFetched] = useState(false);

    useEffect(() => {
        async function fetchAccountDetails() {
            if (!dataFetched && user?.id) {
                try {
                    // Chamada da API usando a rota atualizada
                    const response = await Api.get(`/userrole/user/${user.id}`);
                    setAccountDetails(response.data);
                    setDataFetched(true);
                } catch (error) {
                    console.error("Error fetching account details:", error);
                }
            }
        }

        fetchAccountDetails();
    }, [user?.id, dataFetched]);  // useEffect depende do user.id e dataFetched

    const getAccountType = (rolesObject) => {
        const rolesMap = {
            1709859896: "Conta Administrador",
            1709880127: "Conta Criador",
            1709880136: "Conta Usuário"
        };

        // Converter o objeto de roles para um array e encontrar o primeiro que corresponde
        const rolesArray = Object.values(rolesObject);
        const role = rolesArray.find(role => rolesMap[role.idRoles]);

        return role ? rolesMap[role.idRoles] : "Conta Padrão";
    };

    const accountType = getAccountType(accountDetails);

    return (
        <>
            <TermsOfUse isOpen={termsOfUse} onClose={() => setTermsOfUse(false)} />
            <PrivacyPolicy isOpen={privacyPolicy} onClose={() => setPrivacyPolicy(false)} />
            <HowItWorks isOpen={howItWorks} onClose={() => setHowItWorks(false)} />
            <ProblemReport isOpen={problemReport} onClose={() => setProblemReport(false)} />
            <Container maxW="container.xl" marginTop="20">
                <Grid
                    as="footer"
                    templateColumns={{ base: "1fr 2fr 3fr 2fr 3fr" }}
                    gap={6}
                    padding="1rem"
                    role="contentinfo"
                >
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <VStack spacing={1} alignItems="center">
                            {/* Primeira imagem */}
                            <Image cursor={"pointer"} src={require("../assets/icons/logo2.png")} w={35} />

                            {/* Texto logo acima da segunda imagem */}
                            <Text fontSize="0.6rem" align="center" color="whiteAlpha.700" mt={12}>
                                Desenvolvido por
                            </Text>

                            {/* Segunda imagem com opacidade de 50% */}
                            <Image
                                src={require("../assets/bahtech/logo_bahtech.png")} // Substitua com a imagem correta
                                w={40}
                                mt={2}
                                opacity={0.6}
                            />
                        </VStack>
                    </Box>

                    <Box visibility={user?.id ? 'visible' : 'hidden'}>
                        <Text fontSize="lg">Minha Conta</Text>
                        <Text fontSize="sm" color="whiteAlpha.500">Acesse as ferramentas da sua conta</Text>
                        <Stack direction="column" mt={6} spacing={4}>
                            <Link href="/conta" fontSize="sm" color="whiteAlpha.500">Minha Conta</Link>
                            <Link href="/conta/2" fontSize="sm" color="whiteAlpha.500">Meus Downloads</Link>
                        </Stack>
                    </Box>
                    <Box visibility={user?.id && (accountType === "Conta Administrador" || accountType === "Conta Criador") ? 'visible' : 'hidden'}>
                        <Text fontSize="lg">Creator</Text>
                        <Text fontSize="sm" color="whiteAlpha.500">Ferramentas para você criador</Text>
                        <HStack>
                            <Stack direction="column" mt={6} spacing={4}>
                                <Link href="/conta/3" fontSize="sm" color="whiteAlpha.500">Meus Vídeos</Link>
                                <Link href="#" onClick={() => faturamentoOpenModal()} fontSize="sm" color="whiteAlpha.500">Faturamento</Link>
                            </Stack>
                            <Stack direction="column" ml={5} mt={6} spacing={4}>
                                <Button
                                    onClick={() => setHowItWorks(true)}
                                    fontSize="sm"
                                    color={colors.white50}
                                    variant="link"
                                    fontWeight="normal"
                                    _hover={{ textDecoration: 'underline' }}
                                    width="full" // Assegura que o botão ocupe a largura total
                                    justifyContent="flex-start" // Alinha o conteúdo do botão à esquerda
                                >
                                    <Text textAlign="left">Como funciona?</Text> {/* Texto explicitamente alinhado à esquerda */}
                                </Button>
                            </Stack>
                        </HStack>
                    </Box>

                    <Box>
                        <Text fontSize="lg">Atendimento</Text>
                        <Text fontSize="sm" color={colors.white50}>Suporte Técnico e Ajuda</Text>
                        <Stack direction="column" mt={6} spacing={4}>
                            <Link
                                href="https://wa.me/5551920044576"
                                isExternal
                                fontSize="sm"
                                color={colors.white50}
                            >
                                Contato
                            </Link>

                            <Button
                                onClick={() => setProblemReport(true)}
                                fontSize="sm"
                                color={colors.white50}
                                variant="link"
                                fontWeight="normal"
                                _hover={{ textDecoration: 'underline' }}
                                width="full" // Assegura que o botão ocupe a largura total
                                justifyContent="flex-start" // Alinha o conteúdo do botão à esquerda
                            >
                                <Text textAlign="left">Reporte um Problema</Text> {/* Texto explicitamente alinhado à esquerda */}
                            </Button>
                        </Stack>
                    </Box>

                    <Box maxW={"xs"}>
                        <Text fontSize="xl">Pronto para começar a monetizar seus vídeos?</Text>
                        <YellowButton mt={10} text={"Fazer upload"} icon={<FaUpload />} onClick={() => headerExpand()} />
                    </Box>
                </Grid>
                <Grid
                    as="footer"
                    borderTopWidth={1}
                    mt={7}
                    templateColumns={{ base: "1fr 1fr 1fr" }}
                    w="full"
                    gap={6}
                    paddingY={"30px"}
                    role="contentinfo"
                >
                    <Flex>
                        <Text fontSize="sm" color={colors.white50}>©2024 HausTri.  Todos os direitos reservados</Text>
                    </Flex>

                    <Flex justifyContent="center">
                        <Image src={require("../assets/icons/br.png")} /><Text ml={2} fontSize="sm" color={colors.white50}>Orgulhosamente feito no Brasil</Text>
                    </Flex>

                    <Flex justifyContent="flex-end" gap={2}>
                        <Button
                            onClick={() => setTermsOfUse(true)}
                            fontSize="sm"
                            color={colors.white50}
                            variant="link"
                            fontWeight="normal"
                            _hover={{ textDecoration: 'underline' }}
                            width="full" // Assegura que o botão ocupe a largura total
                            justifyContent="flex-end" // Alinha o conteúdo do botão à esquerda
                        >
                            <Text textAlign="right">Termos de Uso</Text> {/* Texto explicitamente alinhado à esquerda */}
                        </Button>
                        <Button
                            onClick={() => setPrivacyPolicy(true)}
                            fontSize="sm"
                            color={colors.white50}
                            variant="link"
                            fontWeight="normal"
                            _hover={{ textDecoration: 'underline' }}
                            width="full" // Assegura que o botão ocupe a largura total
                            justifyContent="flex-end" // Alinha o conteúdo do botão à esquerda
                        >
                            <Text textAlign="left">Política de Privacidade</Text> {/* Texto explicitamente alinhado à esquerda */}
                        </Button>
                    </Flex>
                </Grid>
            </Container>
        </>
    );
};
