import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Text, Center, VStack, Icon } from '@chakra-ui/react';
import { FaDesktop } from 'react-icons/fa';

const DeviceBlocker = ({ children }) => {
    const [isMobileDevice, setIsMobileDevice] = useState(isMobile);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileDevice(isMobile || window.innerWidth < 768);
        };

        handleResize(); // Execute uma vez para inicializar o estado

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isMobileDevice) {
        return (
            <Center height="100vh" backgroundColor="gray.900" color="white">
                <Box textAlign="center" p={6} rounded="md" bg="gray.800" shadow="lg">
                    <VStack spacing={6}>
                        <Icon as={FaDesktop} boxSize={12} />
                        <Text fontSize="3xl" fontWeight="bold">
                            Acesso não permitido
                        </Text>
                        <Text fontSize="xl">
                            Por favor, use um computador de mesa ou notebook para acessar este site.
                        </Text>
                    </VStack>
                </Box>
            </Center>
        );
    }
    return children;
};

export default DeviceBlocker;
