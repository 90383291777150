import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Avatar, Box, Flex, Grid, HStack, Image, ScaleFade, Text, VStack, Tooltip } from '@chakra-ui/react';
import { FaBolt, FaPlay, FaTrash, FaEye, FaExclamationTriangle, FaDownload, FaFileAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import Api from '../services/api';
import { colors } from '../styles/colors';
import { IconGhostButton, YellowButton } from './button';
import { FastBuy } from './fastBuy';
import { Loading } from './loading';
import { ConfirmationModal } from './confirmationModal';
import { VideoModal } from './videoModal';
import { ErrorModal } from './errorModal';
import { LicenseModal } from './licenseModal';

const formatCurrency = (price) => {
    return parseFloat(price).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
};

export const Videos = ({ videos, setVideos, download = false, isOwnerView = false, openModal = false }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [fastBuy, setFastBuy] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isPlaying, setIsPlaying] = useState({});
    const videoRefs = useRef([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenVideo, setModalOpenVideo] = useState(false);
    const [licenseModalOpen, setLicenseModalOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        videoRefs.current = videos.map(() => React.createRef());
        setHoveredIndex(null);
        setIsPlaying({});
    }, [videos]);

    const tryPlayVideo = (videoElement, index, attempts = 3) => {
        if (!videoElement) return;

        const playPromise = videoElement.play();
        if (playPromise !== undefined) {
            playPromise.then(() => {
                setIsPlaying((prev) => ({ ...prev, [index]: true }));
            }).catch(error => {
                console.error(`Video play failed (attempt ${3 - attempts + 1}):`, error);
                if (attempts > 1) {
                    setTimeout(() => tryPlayVideo(videoElement, index, attempts - 1), 500);
                }
            });
        }
    };

    const handleMouseEnter = useCallback((index) => {
        setHoveredIndex(index);
        const videoElement = videoRefs.current[index]?.current;
        if (videoElement && !isPlaying[index]) {
            tryPlayVideo(videoElement, index);
        }
    }, [isPlaying]);

    const handleMouseLeave = useCallback((index) => {
        const videoElement = videoRefs.current[index]?.current;
        if (videoElement && isPlaying[index]) {
            videoElement.pause();
            videoElement.currentTime = 0;
            setIsPlaying((prev) => ({ ...prev, [index]: false }));
        }
        setHoveredIndex(null);
    }, [isPlaying]);

    const handleTimeUpdate = useCallback((index) => {
        const videoElement = videoRefs.current[index]?.current;
        if (videoElement) {
            const isVideoPlaying = !videoElement.paused;
            setIsPlaying((prev) => ({ ...prev, [index]: isVideoPlaying }));
        }
    }, []);

    const handleFavorite = (video, event) => {
        event.stopPropagation(); // Evita que o clique no botão de favoritar dispare o clique do vídeo
        if (video.isFavorite === 0)
            Api.post('/favorites', { idVideo: video.id })
                .then(response => {
                    setVideos(videos.map(v => v.id === video.id ? { ...v, isFavorite: 1 } : v));
                }).catch(error => {
                    console.error("Failed to favorite video:", error);
                });
        else
            Api.delete('/favorites/video/' + video.id)
                .then(response => {
                    setVideos(videos.map(v => v.id === video.id ? { ...v, isFavorite: 0 } : v));
                }).catch(error => {
                    console.error("Failed to favorite video:", error);
                });
    };

    const handleToggleStatus = (video, event) => {
        event.stopPropagation(); // Evita que o clique no ícone dispare o clique do vídeo
        setSelectedVideo(video);
        setModalOpen(true);
    };

    const toggleVideoStatus = async () => {
        setLoading(true);
        try {
            if (selectedVideo.isOnline) {
                await Api.delete(`/video/${selectedVideo.id}`);
            } else {
                await Api.put(`/video/restore/${selectedVideo.id}`);
            }
            setVideos(videos.map(v => v.id === selectedVideo.id ? { ...v, isOnline: !v.isOnline } : v));
        } catch (error) {
            console.error("Erro ao atualizar o status do vídeo: ", error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    async function downloadVideo(video) {
        setLoading(true);
        try {
            const result = await Api.get("/video/download/" + video.id);
            const url = result.data.url;

            const response = await fetch(url);
            const blob = await response.blob();

            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = video.id + '.mp4';
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(objectUrl);
            document.body.removeChild(a);

            setLoading(false);
        } catch (error) {
            console.error("Erro ao baixar o vídeo: ", error);
            const { response } = error;
            let errorMsg = 'Ocorreu um erro ao baixar o vídeo.';
            if (response) {
                if (response.status === 404) {
                    errorMsg = 'Baseado nas informações de vídeo e de compra, não foi encontrada uma compra válida.';
                } else if (response.status === 429) {
                    errorMsg = 'Limite de requisições de download atingido. Tente novamente após 45 minutos.';
                } else if (response.status === 500) {
                    errorMsg = 'Ocorreu um erro ao gerar o link para download.';
                }
            }
            setErrors([{ msg: errorMsg }]);
            setErrorModalOpen(true);
            setLoading(false);
        }
    }

    const handleVideoClick = (e, video) => {
        // Impede o clique se for em um elemento com a classe ignore-modal
        if (
            e.target.closest('button') ||
            e.target.tagName === 'svg' ||
            e.target.tagName === 'path'
        ) {
            console.log("Clique ignorado");
            return;
        }
        if (openModal) {
            setSelectedVideo(video);
            setModalOpenVideo(true);
        } else {
            navigate("/produto/" + video.id);
        }
    };

    return (
        <>
            <Grid templateColumns="repeat(3, 1fr)" gap={2} overflow="auto" position="relative">
                <FastBuy video={fastBuy} isOpen={fastBuy != null} onClose={() => setFastBuy(null)} />
                {videos.length === 0 && (
                    <HStack w={"full"} justifyContent="space-between">
                        <Text fontSize="md" px={3} py={1} color={colors.white75}>
                            Sem vídeos a serem exibidos aqui.
                        </Text>
                    </HStack>
                )}
                {videos.map((video, index) => (
                    <ScaleFade in={true} key={video.id}>
                        <Box
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                            height="250px"
                            position="relative"
                            overflow="hidden"
                            onClick={(e) => handleVideoClick(e, video)} // Adiciona o manipulador de clique
                        >
                            {/* Imagem de fundo */}
                            <Box
                                background={`url(${video.thumbnailUrl}) no-repeat center center`}
                                backgroundSize="cover"
                                height="100%"
                                width="100%"
                                position="absolute"
                                top={0}
                                left={0}
                                zIndex={0}
                            />

                            {/* Overlay escurecedor */}
                            <Box
                                position="absolute"
                                top={0}
                                left={0}
                                right={0}
                                bottom={0}
                                zIndex={2}
                                backgroundColor="rgba(0, 0, 0, 0.3)" // Cor preta com 50% de opacidade
                            />
                            <video
                                ref={videoRefs.current[index]}
                                crossOrigin="anonymous"
                                style={{
                                    width: '100%',
                                    height: '400px',
                                    display: hoveredIndex === index ? 'block' : 'none',
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 1,
                                    opacity: hoveredIndex === index ? 1 : 0, // Define a opacidade com base no hoveredIndex
                                    transition: "opacity 1s" // Define a transição da opacidade
                                }}
                                loop
                                muted
                                onTimeUpdate={() => handleTimeUpdate(index)} // Adicione este listener
                            >
                                <source src={video.videoUrl} />
                            </video>
                            <VStack
                                position="absolute"
                                top={0}
                                left={0}
                                right={0}
                                bottom={0}
                                justifyContent="space-between"
                                alignItems="flex-start"
                                spacing={4}
                                p={5}
                                zIndex={3}
                                opacity={hoveredIndex === index ? 1 : 0} // Define a opacidade com base no hoveredIndex
                                transition="opacity 1s" // Define a transição da opacidade
                            >
                                <HStack w={"full"} justifyContent="space-between">
                                    <Text></Text>
                                    <Text fontSize="sm" px={3} py={1} color={colors.white75}>
                                        {formatCurrency(video.price)}
                                    </Text>
                                </HStack>
                                <HStack w={"full"} justifyContent="space-between">
                                    <Flex>
                                        <Avatar name={video.fullName} size="sm" backgroundColor={colors.primary} color={colors.black} />
                                        <Text fontSize="xs" ml={2}>{video.state}<Text fontWeight="bold">{video.fullName}</Text></Text>
                                    </Flex>

                                    {!download && !isOwnerView &&
                                        <Flex justifyContent="space-between" gap={2}>
                                            {user && <IconGhostButton className="favorite-button" color={colors.white75} onClick={(e) => handleFavorite(video, e)} icon={<Image src={require(video?.isFavorite === 1 ? "../assets/icons/heartpink.png" : "../assets/icons/heart.png")} w="20px" />} />}
                                            {user && <IconGhostButton color={colors.white75} onClick={() => setFastBuy(video)} icon={<FaBolt />} borderWidth={1} borderColor={colors.white75} />}
                                            <IconGhostButton color={colors.white75} onClick={() => navigate("/produto/" + video.id)} icon={<FaPlay />} borderWidth={1} borderColor={colors.white75} />
                                        </Flex>
                                    }
                                    {download && user &&
                                        <Flex justifyContent="space-between" gap={2}>
                                            <Tooltip
                                                hasArrow
                                                arrowSize={15}
                                                label="Baixar licença do vídeo"
                                                aria-label="Baixar licença do vídeo"
                                                placement="top"
                                                closeOnClick={false}
                                                bg={colors.background}
                                                color={colors.white}
                                                shouldWrapChildren
                                            >
                                                <IconGhostButton
                                                    color={colors.white75}
                                                    onClick={() => {
                                                        setSelectedVideo(video);
                                                        setLicenseModalOpen(true);
                                                    }}
                                                    icon={<FaFileAlt />}
                                                    borderWidth={1}
                                                    borderColor={colors.white75}
                                                />
                                            </Tooltip>
                                            {loading ? <Loading p={0} /> : <YellowButton text="Download" onClick={() => downloadVideo(video)} icon={<FaDownload />} />}
                                        </Flex>
                                    }
                                    {isOwnerView &&
                                        <Flex justifyContent="space-between" gap={2}>
                                            {/* Botão de alerta para vídeos rejeitados ou em aprovação */}
                                            {video.IsApproval === false && (
                                                <Tooltip
                                                    hasArrow
                                                    arrowSize={15}
                                                    label={video.RejectReason ? video.RejectReason : "O vídeo está em aprovação pelo moderador"}
                                                    aria-label={video.RejectReason ? "Motivo da Rejeição" : "O vídeo está em aprovação pelo moderador"}
                                                    placement="top"
                                                    closeOnClick={false}
                                                    bg={colors.background}
                                                    color={colors.white}
                                                    shouldWrapChildren
                                                >
                                                    <IconGhostButton
                                                        color={colors.white75}
                                                        icon={<FaExclamationTriangle />}
                                                        borderWidth={1}
                                                        borderColor={colors.white75}
                                                    />
                                                </Tooltip>
                                            )}
                                            <IconGhostButton
                                                color={colors.white75}
                                                onClick={(e) => handleToggleStatus(video, e)}
                                                icon={video.isOnline ? <FaTrash /> : <FaEye />}
                                                borderWidth={1}
                                                borderColor={colors.white75}
                                            />
                                        </Flex>
                                    }
                                </HStack>
                            </VStack>
                        </Box>
                    </ScaleFade>
                ))}
            </Grid>
            <ConfirmationModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={toggleVideoStatus}
                video={selectedVideo}
            />
            {selectedVideo && (
                <VideoModal
                    isOpen={modalOpenVideo}
                    onClose={() => setModalOpenVideo(false)}
                    video={selectedVideo}
                />
            )}
            <ErrorModal
                isOpen={errorModalOpen}
                onClose={() => setErrorModalOpen(false)}
                errors={errors}
            />
            {selectedVideo && (
                <LicenseModal
                    isOpen={licenseModalOpen}
                    onClose={() => setLicenseModalOpen(false)}
                    videoId={selectedVideo.id}
                />
            )}
        </>
    );
};