import { Avatar, Box, Container, Flex, Grid, GridItem, HStack, Image, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import 'moment/locale/pt-br'; // Importar o locale PT-BR
import React, { useEffect, useState } from 'react';
import { FaDollarSign } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import back from '../../assets/header/backacc.png';
import { GhostButton, IconGhostButton, YellowButton } from '../../components/button';
import { ButtonGroupCustom } from '../../components/buttonGroup';
import { Footer } from '../../components/footer';
import Header from '../../components/header';
import { Loading } from '../../components/loading';
import { CustomTag } from '../../components/tag';
import { Videos } from '../../components/videos';
import { useAuth } from '../../hooks/auth';
import Api from '../../services/api';
import { colors } from '../../styles/colors';
import { ErrorModal } from '../../components/errorModal';

moment.locale('pt-br');

const Account = () => {
  const { user } = useAuth();
  const { active } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [activeFilter, setActiveFilter] = useState(active ? (parseInt(active) ?? 1) : 1);
  const [dataFilter1, setDataFilter1] = useState(null);
  const [dataFilter2, setDataFilter2] = useState(null);
  const [dataFilter3, setDataFilter3] = useState(null);
  const [dataFilter4, setDataFilter4] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    // Função para carregar os dados do filtro ativo
    const fetchData = async () => {
      const filterParams = {
        page: 1,
        limitPerPage: 10000,
        latestAcquisitions: activeFilter === 1,
        gallery: activeFilter === 2,
        uploads: activeFilter === 3,
        favorites: activeFilter === 4
      };

      try {
        const response = await Api.post(`/users/account/filters`, filterParams);
        if (activeFilter === 1) {
          setDataFilter1(response.data);
        } else if (activeFilter === 2 || activeFilter === 3 || activeFilter === 4) {
          const getAll = response.data.videos.map(x => ({
            id: x.idVideo,
            title: x.titleScene,
            description: x.descriptionScene,
            price: x.price,
            isNews: x.isNews,
            commissionType: x.idCommissionValueType,
            thumbnailUrl: x.thumbMomentImgLink,
            videoUrl: x.videoLinkCompressedAndWatermark,
            city: x.city,
            local: x.local,
            state: x.state,
            displayName: x.displayName,
            fullName: x.fullName,
            resolution: x.resolution,
            duration: x.duration,
            isFavorite: x.isFavorite,
            totalDownloads: x.totalDownloads,
            categories: x.categories.map(cat => ({ id: cat.idCategories, description: cat.description })),
            tags: x.tags.map(tag => ({ id: tag.IdTags, description: tag.Description })),
            isOnline: x.isOnline,
            IsApproval: x.isApproval,
            RejectReason: x.rejectReason,
            onClick: () => navigate("/produto/" + x.idVideo)
          }));

          if (activeFilter === 2)
            setDataFilter2(getAll);
          else if (activeFilter === 3)
            setDataFilter3(getAll);
          else if (activeFilter === 4)
            setDataFilter4(getAll);
        }
      } catch (error) {
        console.error("Error fetching data for filter:", error);
      }
    };

    if (user?.id) {
      fetchData();
    }
  }, [user?.id, activeFilter]);

  const renderContentAsFiltered = () => {
    switch (activeFilter) {
      case 1:
        return dataFilter1 && dataFilter1.length > 0 ? (
          <Box mt={30}>
            <Text fontSize="xl" mb={10}>
              Vídeos comprados
            </Text>
            <SimpleGrid columns={7} spacing={5} mt={5} alignItems="center">
              <Text fontSize="sm" fontWeight="bold" color={colors.white75}>Descrição</Text>
              <Text fontSize="sm" fontWeight="bold" color={colors.white75}>Status</Text>
              <Text fontSize="sm" fontWeight="bold" color={colors.white75}>Transação/ID</Text>
              <Text fontSize="sm" fontWeight="bold" color={colors.white75}>Data</Text>
              <Text fontSize="sm" fontWeight="bold" color={colors.white75}>Tipo</Text>
              <Text fontSize="sm" fontWeight="bold" color={colors.white75}>Valor</Text>
              <Box></Box>
              {dataFilter1.map((purchase) => (
                <React.Fragment key={purchase.idPurchaseOrder}>
                  <Text color={colors.white50}>
                    {purchase.videos.slice(0, 2).map(video => video.idVideo).join(', ')}
                    {purchase.videos.length > 2 ? ` +${purchase.videos.length - 2} itens` : ''}
                  </Text>
                  <Text color={colors.white50}>{purchase.status}</Text>
                  <Text color={colors.white50}>{purchase.idPurchaseOrder}</Text>
                  <Text color={colors.white50}>{moment(purchase.transactionDateRequest).format('DD MMMM YYYY')}</Text>
                  <Text color={colors.white50}>{purchase.paymentTypeDescription}</Text>
                  <Text color={colors.white50}>{formatCurrency(purchase.totalPurchase)}</Text>
                  <Box display="flex" gap={4} justifyContent="flex-end">
                    {loadingVideos ? <Loading p={0} /> : <YellowButton text="Download Vídeos" onClick={() => download(purchase.videos)} />}
                  </Box>
                </React.Fragment>
              ))}
            </SimpleGrid>
          </Box>
        ) : <Text>Nenhum conteúdo disponível</Text>;
      case 2:
        return dataFilter2 && dataFilter2.length > 0 ? (
          <Box>
            {<Videos videos={dataFilter2} setVideos={setDataFilter2} download openModal />}
          </Box>
        ) : <Text>Nenhum conteúdo disponível</Text>;
      case 3:
        return dataFilter3 && dataFilter3.length > 0 ? (
          <Box>
            {<Videos videos={dataFilter3} setVideos={setDataFilter3} isOwnerView openModal />}
          </Box>
        ) : <Text>Nenhum conteúdo disponível</Text>;
      case 4:
        return dataFilter4 && dataFilter4.length > 0 ? (
          <Box>
            {<Videos videos={dataFilter4} setVideos={setDataFilter4} />}
          </Box>
        ) : <Text>Nenhum conteúdo disponível</Text>;
      default:
        return <Text>Nenhum conteúdo disponível</Text>;
    }
  };

  const [accountDetails, setAccountDetails] = useState({
    idUser: null,
    displayName: '',
    city: '',
    state: '',
    professions: [],
    roles: [],
    onlyUser: false,
    totalPurchasedVideos: 0,
    totalUploads: 0,
    totalSalesQtd: 0,
    currentMonthSales: 0,
    lastMonthSales: 0,
    percentChange: 0,
    categoriesPopular: [],
    currentMonthViews: 0,
    lastMonthViews: 0,
    percentChangeViews: 0,
    availableBalance: 0,
    pendingBalance: 0
  });
  const [dataFetched, setDataFetched] = useState(false);  // State to control the fetch operation

  async function fetchAccountDetails() {
    if (!dataFetched) {
      try {
        const response = await Api.get(`/users/account/${user.id}`);
        setAccountDetails(response.data);
        setDataFetched(true);  // Set the flag as true after data is fetched
        setLoading(false);
      } catch (error) {
        console.error("Error fetching account details:", error);
      }
    }
  }

  useEffect(() => {
    fetchAccountDetails();
  }, [user?.id, dataFetched]);

  // Função para determinar o tipo de conta baseada nas roles
  const getAccountType = (roles) => {
    // Mapeamento das roles para identificar facilmente a descrição baseada no ID da role
    const rolesMap = {
      1709859896: "Conta Administrador",
      1709880127: "Conta Criador",
      1709880136: "Conta Usuário"
    };

    // Encontrar a primeira role que corresponda às condições especificadas
    const role = roles.find(role => rolesMap[role.idRoles]);

    // Retornar a descrição correspondente ou "Conta Padrão" se nenhum ID conhecido for encontrado
    return role ? rolesMap[role.idRoles] : "Conta Padrão";
  };

  const getProfessionsDescription = () => {
    // Verifica se o array de profissões existe e tem elementos
    if (!accountDetails.professions || accountDetails.professions.length === 0) {
      return '';  // Retorna uma string vazia se não houver profissões
    }

    // Pegar até três descrições de profissões
    const professionsText = accountDetails.professions
      .slice(0, 3)  // limitar a três elementos
      .map(prof => prof.description)  // extrair descrições
      .join(" | ");  // juntar com " | "

    // Formatar a string final com cidade e estado
    return `${professionsText} - ${accountDetails.city} - ${accountDetails.state}`;
  };

  const formatCurrency = (price) => {
    return parseFloat(price).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const renderPercentChange = () => {
    const percentChange = parseFloat(accountDetails.percentChange);
    if (percentChange > 0) {
      return <Text fontSize="xs" color={colors.green} mb={2}>{`+${percentChange}%`}</Text>;
    } else if (percentChange < 0) {
      return <Text fontSize="xs" color={colors.red} mb={2}>{`${percentChange}%`}</Text>;
    }
    // Não retorna nada se percentChange for 0
  };

  const renderPercentChangeViews = () => {
    const percentChangeViews = parseFloat(accountDetails.percentChangeViews);
    if (percentChangeViews > 0) {
      return <Text fontSize="xs" color={colors.green} mb={2}>{`+${percentChangeViews}%`}</Text>;
    } else if (percentChangeViews < 0) {
      return <Text fontSize="xs" color={colors.red} mb={2}>{`${percentChangeViews}%`}</Text>;
    }
    // Não retorna nada se percentChangeViews for 0
  };

  async function withdrawal(value) {
    setLoading(true);
    await Api.post("/withdrawal/request", { amount: value });
    const response = await Api.get(`/users/account/${user.id}`);
    setAccountDetails(response.data);
    setLoading(false);
  }

  async function download(videos) {
    setLoadingVideos(true);
    setErrors([]);
    setErrorModalOpen(false);

    try {
      const downloadPromises = videos.map(async (x) => {
        try {
          const result = await Api.get("/video/download/" + x.idVideo);
          const url = result.data.url;

          const response = await fetch(url);
          const blob = await response.blob();

          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = x.idVideo + '.mp4';
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(objectUrl);
          document.body.removeChild(a);
        } catch (error) {
          console.error("Erro ao baixar o vídeo: ", error);
          if (error.response) {
            setErrors(prevErrors => [...prevErrors, { msg: error.response.data.message || "Erro desconhecido", status: error.response.status }]);
          } else {
            setErrors(prevErrors => [...prevErrors, { msg: "Erro ao baixar o vídeo", status: 500 }]);
          }
          throw error;
        }
      });

      await Promise.all(downloadPromises);
    } catch (error) {
      console.error("Erro ao baixar um ou mais vídeos: ", error);
      setErrorModalOpen(true);
    } finally {
      setLoadingVideos(false);
    }
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Header />
        <div style={{ paddingBottom: 25, paddingTop: 25, position: 'relative', backgroundColor: "transparent" }}>
          <div style={{
            position: 'absolute',
            top: -100,
            left: 0,
            width: '100%',
            height: "100vh",
            backgroundImage: `url(${back})`,
            zIndex: -1,
            pointerEvents: 'none',
            backgroundSize: 'cover',
            opacity: 1
          }} />
          <Container maxW="container.xl" justifyContent="space-between" alignItems="center">
            <Grid templateColumns="repeat(3, 1fr)" spacing={5}>
              <GridItem colSpan={1}>
                <HStack h="full" p={4} align="flex-start" justifyContent="flex-start" mb={5}>
                  <Avatar name={user?.name} backgroundColor={colors.primary} size="xl" />
                  <VStack align="flex-start" justifyContent="center" ml={5} spacing={1}>
                    <Text fontSize="xs" borderWidth={1} paddingX={3} paddingY={1} borderRadius={20} borderColor={colors.white75} color={colors.white75} fontWeight="500">
                      {getAccountType(accountDetails.roles)}
                    </Text>
                    <Text fontSize="3xl" fontWeight="500" color={colors.white}>
                      {accountDetails.displayName ? accountDetails.displayName : user?.name}
                    </Text>
                    <Text fontSize="sm" fontWeight="300" color={colors.white75}>
                      {getProfessionsDescription()}
                    </Text>
                  </VStack>
                </HStack>
              </GridItem>

              {
                !accountDetails.onlyUser ? <GridItem colSpan={2}>
                  <Flex h="full" alignItems="center" justifyContent="flex-end" gap={3} p={4} borderRadius="md" color="white">
                    {loading ? <Loading /> : <>
                      {accountDetails.availableBalance > accountDetails.pendingWithdrawalsTotal && (
                        <GhostButton text={"Solicitar saque"} onClick={() => withdrawal(accountDetails.availableBalance - accountDetails.pendingWithdrawalsTotal)} borderColor={colors.primary} borderWidth={1} ml={10} color={colors.primary} />
                      )}

                      <Box display="flex" alignItems="center">
                        <IconGhostButton disabled={true} backgroundColor={colors.primary} icon={<Image w={'17.5px'} src={require("../../assets/header/bag_black.png")} />} borderWidth={1} />
                        <Box ml={3}>
                          <Text fontWeight="bold" color={colors.white75}>
                            {formatCurrency(accountDetails.availableBalance)}
                          </Text>
                          <Text fontSize="sm" color={colors.white50}>
                            Disponível para saque
                          </Text>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <IconGhostButton disabled={true} backgroundColor={colors.primary} icon={<Image w={'17.5px'} src={require("../../assets/header/bag_black.png")} />} borderWidth={1} />
                        <Box ml={3}>
                          <Text fontWeight="bold" color={colors.white75}>
                            {formatCurrency(accountDetails.pendingWithdrawalsTotal)}
                          </Text>
                          <Text fontSize="sm" color={colors.white50}>
                            Pendente (saque solicitado)
                          </Text>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <IconGhostButton disabled={true} backgroundColor={colors.primary} icon={<FaDollarSign color={colors.black} />} borderWidth={1} />
                        <Box ml={3}>
                          <Text fontWeight="bold" color={colors.white75}>
                            {formatCurrency(accountDetails.pendingBalance)}
                          </Text>
                          <Text fontSize="sm" color={colors.white50}>
                            Pendente (compras recentes)
                          </Text>
                        </Box>
                      </Box>
                    </>}
                  </Flex>
                </GridItem>
                  : <></>
              }
            </Grid>

            {!accountDetails.onlyUser ?
              < Grid visibility={accountDetails.onlyUser ? 'hidden' : 'visible'} templateColumns="2fr 1fr 2fr 1fr" gap={6} mt={10}>
                <GridItem p={10} borderRadius="lg" borderWidth={1} borderColor={colors.blackBorder}>
                  <Text fontSize="sm">Seus números</Text>
                  <Text fontSize="xs" color={colors.white50} mb={2}>Todos as métricas da sua conta</Text>

                  <HStack align="center" justifyContent="space-between" mt={10}>
                    <Box display="flex" alignItems="center">
                      <IconGhostButton disabled={true} backgroundColor={colors.grayBack} borderColor={colors.grayBack} icon={<Image w={'17.5px'} src={require("../../assets/header/bag.png")} />} borderWidth={1} />
                      <Box ml={3}>
                        <Text fontSize="lg" color={colors.white75} fontWeight="600">{accountDetails.totalSalesQtd}</Text>
                        <Text fontSize="xs" color={colors.white50} mb={2}>Vídeos</Text>
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center">
                      <IconGhostButton disabled={true} backgroundColor={colors.grayBack} borderColor={colors.grayBack} icon={<Image w={'17.5px'} src={require("../../assets/header/bag.png")} />} borderWidth={1} />
                      <Box ml={3}>
                        <Text fontSize="lg" color={colors.white75} fontWeight="600">{accountDetails.totalUploads}</Text>
                        <Text fontSize="xs" color={colors.white50} mb={2}>Uploads</Text>
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center">
                      <IconGhostButton disabled={true} backgroundColor={colors.grayBack} borderColor={colors.grayBack} icon={<Image w={'17.5px'} src={require("../../assets/header/bag.png")} />} borderWidth={1} />
                      <Box ml={3}>
                        <Text fontSize="lg" color={colors.white75} fontWeight="600">{accountDetails.totalPurchasedVideos}</Text>
                        <Text fontSize="xs" color={colors.white50} mb={2}>Vendas</Text>
                      </Box>
                    </Box>
                  </HStack>
                </GridItem>

                <GridItem p={10} borderRadius="lg" borderWidth={1} borderColor={colors.blackBorder}>
                  <Text fontSize="sm">Vendas</Text>
                  <Text fontSize="xs" color={colors.white50} mb={2}>Total deste mês</Text>

                  <Text fontSize="lg" color={colors.white75} fontWeight="600" mt={10}>{accountDetails.currentMonthSales}</Text>
                  {renderPercentChange()}
                </GridItem>

                <GridItem p={10} borderRadius="lg" borderWidth={1} borderColor={colors.blackBorder}>
                  <Text fontSize="sm">Popularidade</Text>
                  <Text fontSize="xs" color={colors.white50} mb={2}>Categorias mais visualizadas</Text>

                  <Flex mt={10} wrap="wrap" gap={2}>
                    {accountDetails?.categoriesPopular.slice(0, 7).map((category, index) => (
                      <CustomTag key={index} text={category.description} />
                    ))}
                  </Flex>
                </GridItem>
                <GridItem p={10} borderRadius="lg" borderWidth={1} borderColor={colors.blackBorder}>
                  <Text fontSize="sm">Views</Text>
                  <Text fontSize="xs" color={colors.white50} mb={2}>Total de Visualizações</Text>

                  <Text fontSize="lg" color={colors.white75} fontWeight="600" mt={10}>{accountDetails.currentMonthViews}</Text>
                  {renderPercentChangeViews()}
                </GridItem>
              </Grid>
              : <></>
            }

            <Box mt={50} mb={5}>
              <Flex justify="center">
                <ButtonGroupCustom
                  active={activeFilter}
                  buttons={[
                    { id: 1, text: "Últimas Aquisições", onClick: () => setActiveFilter(1) },
                    { id: 2, text: "Galeria", onClick: () => setActiveFilter(2) },
                    ...(accountDetails.onlyUser ? [] : [{ id: 3, text: "Uploads", onClick: () => setActiveFilter(3) }]),
                    { id: 4, text: "Coleção", onClick: () => setActiveFilter(4) }
                  ]}
                />
              </Flex>
            </Box>

            {renderContentAsFiltered()}

          </Container>
        </div >
      </div >

      <Footer />

      <ErrorModal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        errors={errors}
      />
    </>
  );
};

export default Account;
