import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { colors } from '../styles/colors';
import { GhostButton, WhiteButton } from './button';

export const VideoSliderHome = ({ videos }) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isPlaying, setIsPlaying] = useState({});
    const [videoRefs, setVideoRefs] = useState([]);

    useEffect(() => {
        setVideoRefs(videos.map(() => React.createRef()));
    }, [videos]);

    const handleMouseEnter = useCallback((index) => {
        setHoveredIndex(index);
        if (videoRefs[index] && videoRefs[index].current && !isPlaying[index]) {
            const playPromise = videoRefs[index].current.play();
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    setIsPlaying({ ...isPlaying, [index]: true });
                }).catch(error => {
                    console.error("Falha ao reproduzir o vídeo:", error);
                    // Tentar reproduzir novamente após 1 segundo
                    setTimeout(() => {
                        videoRefs[index].current.play().catch(error => {
                            console.error("Segunda tentativa falhou:", error);
                        });
                    }, 1000);
                });
            }
        }
    }, [videoRefs, isPlaying]);

    const handleMouseLeave = useCallback((index) => {
        if (videoRefs[index] && videoRefs[index].current && isPlaying[index]) {
            videoRefs[index].current.pause();
            videoRefs[index].current.currentTime = 0;
            setIsPlaying({ ...isPlaying, [index]: false });
        }
        setHoveredIndex(null);
    }, [videoRefs, isPlaying]);

    const handleTimeUpdate = useCallback((index) => {
        if (videoRefs[index] && videoRefs[index].current) {
            const isVideoPlaying = !videoRefs[index].current.paused;
            setIsPlaying({ ...isPlaying, [index]: isVideoPlaying });
        }
    }, [videoRefs, isPlaying]);

    return (
        <HStack overflow="auto" position="relative">
            {videos.map((video, index) => (
                <Box
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                    transition="width 0.5s ease-in-out"
                    height="400px"
                    width={hoveredIndex === index ? '45%' : '25%'}
                    borderRadius="5px"
                    position="relative"
                    overflow="hidden"
                    onClick={video.onClick}
                >
                    {/* Imagem de fundo */}
                    <Box
                        background={`url(${video.thumbnailUrl}) no-repeat center center`}
                        backgroundSize="cover"
                        height="100%"
                        width="100%"
                        position="absolute"
                        top={0}
                        left={0}
                        zIndex={0}
                    />

                    {/* Overlay escurecedor */}
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        zIndex={2}
                        backgroundColor="rgba(0, 0, 0, 0.3)" // Cor preta com 30% de opacidade
                    />
                    <video
                        ref={videoRefs[index]}
                        crossOrigin="anonymous"
                        style={{
                            width: '100%',
                            height: '400px',
                            display: hoveredIndex === index ? 'block' : 'none',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            borderRadius: '5px',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1
                        }}
                        loop
                        muted
                        onTimeUpdate={() => handleTimeUpdate(index)}
                    >
                        <source src={video.videoUrl} />
                    </video>
                    <VStack
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={4}
                        p={10}
                        zIndex={3}
                        maxW={"275px"}
                    >
                        <Box>
                            <Text fontSize="2xl" pr={20} color={colors.white} fontWeight="bold">
                                {video.text}
                            </Text>
                            <Text fontSize="sm" mt={2} pr={10} color={colors.white75}>
                                {video.description}
                            </Text>
                        </Box>
                        <Box>
                            {hoveredIndex === index ?
                                <WhiteButton
                                    text="Visualizar Conteúdo"
                                    onClick={video.onClick}
                                /> :
                                <GhostButton
                                    text="Visualizar Conteúdo"
                                    borderWidth={1}
                                    borderColor={colors.white75}
                                    onClick={video.onClick}
                                />}
                        </Box>
                    </VStack>
                </Box>
            ))}
        </HStack>
    );
};
